import(/* webpackMode: "eager", webpackExports: ["TimeZoneChangeDetector"] */ "/vercel/path0/apps/web/src/app/[locale]/timezone-change-detector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageView"] */ "/vercel/path0/apps/web/src/app/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/apps/web/src/components/user-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RelativeDate"] */ "/vercel/path0/apps/web/src/features/quick-create/components/relative-date.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/vercel/path0/apps/web/src/i18n/client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCProvider"] */ "/vercel/path0/apps/web/src/trpc/client/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConnectedDayjsProvider"] */ "/vercel/path0/apps/web/src/utils/dayjs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/vercel/path0/node_modules/motion/dist/es/motion/lib/react.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/vercel/path0/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogProvider"] */ "/vercel/path0/packages/posthog/src/client/index.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/src/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/packages/ui/src/tooltip.tsx");
